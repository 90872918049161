
body {
    background-color: #f1f4f8 !important;
}

a {
    text-decoration: none;
}

 .menu-bar-item-inactive, .menu-bar-item-inactive * {
    color: #000;
}
.menu-bar-item-active, .menu-bar-item-active svg{
    color: #fff;
}
.menu-bar-item-active {
    background: #092635;
}

.menu-bar-item-active, .menu-bar-item-active * {
    font-weight: 600 !important;
}
.error-message{
    color: #d32f2f;
    font-size: 11px;
}
.custom-disabled{
    pointer-events: none !important;
    opacity: .6;
    user-select: none !important;
}
.custom-disabled .MuiInputBase-root {
    pointer-events: none !important;
    opacity: 0.6 !important;
    user-select: none !important;
}
.letterList td:nth-last-child(2){
    display: none;
}
.MuiFormLabel-root{
    color: #494949 !important;
}
.period-filter .MuiAutocomplete-endAdornment {
    display: none;
}
